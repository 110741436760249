import React from 'react'
import Section from './Section'
import Menu from '../layout/Menu'

const Index10 = () => {
  return (
    <React.Fragment>
        <Menu />
        <Section />
    </React.Fragment>
  )
}

export default Index10