import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

// Custom Hook
import useScroll from "../components/useScroll";
import useActiveLink from "../components/useActiveLink";

// Image
import logoBranco from "../assets/images/fonbeat-logo.png";
import logoPreto from "../assets/images/fonbeat-logo-preto.png";

const Menu = () => {
  // scroll page state call
  const navClass = useScroll();

  // activation
  const { activeLink, handleLinkClick } = useActiveLink(
    ".navbar-nav li.active"
  );

  // modal
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top navbar-custom tarnsperant-sticky " +
          navClass
        }
        id="navbar"
      >
        <Container>
          <Link className="navbar-brand logo" to="#">
            <img
              src={logoBranco}
              className="logo-light"
              alt="FONBEAT OFICIAL"
              height="20"
            />
            <img
              src={logoPreto}
              className="logo-dark"
              alt="FONBEAT OFICIAL"
              height="20"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </button>
          <Navbar.Collapse id="navbarSupportedContent" in={isOpen}>
            <ul className="navbar-nav ms-auto navbar-center">
              <li
                className={`nav-item ${
                  activeLink === "/inicio" ? "active" : ""
                }}`}
              >
                <Nav.Link
                  href="#inicio"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/inicio");
                  }}
                >
                  inicio
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/sobre" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#sobre"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/sobre");
                  }}
                >
                  sobre
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/servicos" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#servicos"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/serviços");
                  }}
                >
                  serviços
                </Nav.Link>
              </li>
              {/* <li
                className={`nav-item ${
                  activeLink === "/orcamento" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#orcamento"
                  onClick={() => handleLinkClick("/orçamento")}
                >
                  orçamento
                </Nav.Link>
              </li> */}
              <li
                className={`nav-item ${
                  activeLink === "/pacotes" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#pacotes"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/pacotes");
                  }}
                >
                  pacotes
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/agenda" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#agenda"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/agenda");
                  }}
                >
                  agenda
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${activeLink === "/time" ? "active" : ""}`}
              >
                <Nav.Link
                  href="#time"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/time");
                  }}
                >
                  time
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/portifolio" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#portifolio"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/portifólio");
                  }}
                >
                  portifólio
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/contato" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#contato"
                  onClick={() => {
                    toggle();
                    handleLinkClick("/contato");
                  }}
                >
                  contato
                </Nav.Link>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default Menu;
