import { Col, Container, Row } from "react-bootstrap";
import teamMembers from "../consts/teamMembers.const";
import TeamMembers from "../interfaces/teamMembers.interface";

const TimeComponent: React.FC = () => {
  return (
    <section className="section" id="time">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                Nosso Time
              </h2>
              <p className="heading-title-desc text-muted mt-4">
                Aqui você encontra as pessoas que fazem parte deste time do
                FONBEAT e fazem tudo isso acontecer! Da uma olhadinha nos
                integrantes desse time.
              </p>
            </div>
          </Col>
        </Row>

        <div className="row mt-5 pt-3">
          {(teamMembers || [])?.map((item: TeamMembers, index: number) => (
            <Col lg={4} key={index}>
              <div className="team p-4 text-center mt-4">
                <div className="agency-team-img">
                  {/* <img
                    src={item.picture}
                    className="img-fluid rounded-circle"
                    alt=""
                  /> */}
                </div>
                <h5 className="mt-4 text-uppercase letter-spacing_2 f-15">
                  {item.name}
                </h5>
                <p className="text-muted f-13">{item.role}</p>
                <p className="text-muted mb-0">{item.description}</p>
              </div>
            </Col>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default TimeComponent;
