import React, { useState } from "react";
import "../assets/css/VideoModal.css"; // Para os estilos da modal
import { videos } from "../consts/videos.const";

const VideoGallery = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const handleOpenModal = (url: string) => {
    setSelectedVideo(url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedVideo("");
    setModalOpen(false);
  };

  return (
    <div>
      {/* Gallery Section */}
      <div className="video-gallery">
        {videos.map((video) => (
          <div
            key={video.id}
            className="video-item"
            onClick={() => handleOpenModal(video.url)}
          >
            {/* Miniatura */}
            <img
              src={video.thumbnail}
              alt={video.title}
              className="video-thumbnail"
            />

            {/* Overlay Permanente */}
            <div className="video-overlay">
              <i className="mdi mdi-play-circle-outline video-icon-play"></i>
            </div>

            {/* Título do vídeo */}
            <p className="video-title">{video.title}</p>
          </div>
        ))}
      </div>

      {/* Modal Section */}
      {isModalOpen && (
        <div className="video-modal">
          {/* Overlay */}
          <div className="modal-overlay" onClick={handleCloseModal}></div>

          {/* Modal Content */}
          <div className="modal-content">
            <button className="close-button" onClick={handleCloseModal}>
              X
            </button>
            <iframe
              src={selectedVideo}
              title="YouTube Video"
              allow="autoplay; encrypted-media"
              allowFullScreen
              frameBorder="0"
              className="video-iframe"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};
export default VideoGallery;
