import TeamMembers from "../interfaces/teamMembers.interface";

import user1 from "../assets/images/users/user-1.jpg";
import user2 from "../assets/images/users/user-2.jpg";
import user3 from "../assets/images/users/user-3.jpg";

const teamMembers: TeamMembers[] = [
  {
    name: "Afonso Inocente",
    role: "DJ/Produtor",
    picture: user1,
    description: "",
  },
  {
    name: "Rafaela Araújo",
    role: "Equipe de Negócios",
    picture: user3,
    description: "",
  },
  {
    name: "Guilherme Mattos",
    role: "Equipe de Marketing",
    picture: user1,
    description: "",
  },
  {
    name: "Felipe Antônio",
    role: "Equipe de Técnica",
    picture: user2,
    description: "",
  },
  {
    name: "Claudemir da Silva",
    role: "Equipe Técnica",
    picture: user2,
    description: "",
  },
  {
    name: "Marcio Silva",
    role: "Equipe Técnica",
    picture: user2,
    description: "",
  },
];

export default teamMembers;
