import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import pricingData from "../consts/pricingData.const";
import PricingData from "../interfaces/pricing.interface";

const PacotesComponent: React.FC = () => {
  return (
    <section className="section fonbeat-pricing" id="pacotes">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                PACOTES DE CONTRATAÇÃO
              </h2>
              <p className="heading-title-desc text-muted mt-4">
                Aqui você encontra alguns dos nossos pacotes padrões de
                contratação.
                <br />
                Fique sempre à vontade para entrar em contato conosco via
                Whatsapp e vamos esclarecer todas as suas dúvidas e montar um
                orçamento personalizado especificamente pra você!
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 pt-3">
          {(pricingData || [])?.map((item: PricingData, index: number) => (
            <Col lg={4} key={index}>
              <div className={`${item.active} p-5 mt-4 h-100`}>
                <div className="text-center">
                  <div className="fonbeat-pricing-icon">
                    <i style={item.iconStyle} className={item.icon}></i>
                  </div>

                  <h5 className="mt-4 letter-spacing_4 text-uppercase f-18">
                    {item.title}
                  </h5>
                  {item.tag && (
                    <span
                      className="pacotes-mais-contratado"
                      style={{
                        backgroundColor: "#3CB371",
                        padding: "5px 10px 5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      {item?.tag}
                    </span>
                  )}
                </div>

                {item.features && item.features.length > 0 && (
                  <div
                    className="non-features mt-3"
                    style={{
                      paddingTop: item.tag ? "1rem" : "3rem",
                    }}
                  >
                    {item.features.map((nonFeature, index) => (
                      <p
                        key={`${index}-${index}`}
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          fontSize: "14px",
                          lineHeight: "1",
                          textAlign: "center",
                          paddingBottom: "5px",
                        }}
                      >
                        {nonFeature}
                      </p>
                    ))}
                  </div>
                )}

                {item.nonFeatures && item.nonFeatures.length > 0 && (
                  <div className="non-features mt-3">
                    {item.nonFeatures.map((nonFeature, index) => (
                      <p
                        key={`${index}-${index}`}
                        style={{
                          textDecoration: "line-through",
                          color: "#999",
                          fontSize: "14px",
                          lineHeight: "1",
                          textAlign: "center",
                          paddingBottom: "5px",
                        }}
                      >
                        {nonFeature}
                      </p>
                    ))}
                  </div>
                )}

                <div className="mt-4 pt-3 text-center">
                  <Link
                    to={item.link}
                    target="_blank"
                    className={`btn btn-md ${item.btnClass}`} // Adiciona item.btnClass dinamicamente
                    style={{
                      backgroundColor: "#3CB371",
                      color: "#fff",
                    }}
                  >
                    CONTRATAR
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PacotesComponent;
