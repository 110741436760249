// pages
import Index from "../pages/Index";
import LinksPage from "../pages/LinksPage";

const routes = [
  { path: "/", component: <Index /> },
  { path: "/links", component: <LinksPage /> },
];

export { routes };
