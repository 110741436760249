import "../assets/css/personalized.css";
import React, { useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import servicesData from "../consts/servicesData.const";
import ServicesData from "../interfaces/service.interface";

const ServicosComponent: React.FC = () => {
  const [show, setShow] = useState(false);
  const [currentService, setCurrentService] = useState<ServicesData | null>(
    null
  );

  const handleShow = (service: ServicesData) => {
    setCurrentService(service);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentService(null);
  };

  return (
    <section className="section fonbeat-servicos" id="servicos">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                SERVIÇOS OFERECIDOS
              </h2>
              <p className="heading-title-desc text-muted mt-4">
                Aqui você entende um pouco mais sobre os serviços que
                oferecemos!
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 pt-3">
          {(servicesData || []).map((service: ServicesData, index: number) => (
            <Col lg={4} key={index}>
              <div className="fonbeat-servicos-box p-3 mt-4">
                <div className="fonbeat-servicos-icon">
                  <i className={service.icon}></i>
                </div>

                <h4 className="fonbeat-servicos-title mt-4 f-15 letter-spacing_2 text-uppercase d-flex align-items-center">
                  {service.title}
                  {service.image && (
                    <Button
                      variant="link"
                      className="ms-2 p-0"
                      onClick={() => handleShow(service)}
                      style={{
                        fontSize: "1.2rem",
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      <i className="mdi mdi-information-outline"></i>
                    </Button>
                  )}
                </h4>
                <p className="text-muted mt-4 mb-0">
                  {service.description.split("(i)").map((part, index) => (
                    <React.Fragment key={index}>
                      {part}
                      {index !==
                        service.description.split("(i)").length - 1 && (
                        <i
                          style={{
                            fontSize: "1rem",
                            textDecoration: "none",
                          }}
                          className="mdi mdi-information-outline"
                        />
                      )}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </Col>
          ))}
        </Row>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{currentService?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentService?.descriptionAtModal && currentService?.description}
            <br />
            <br />
            <img
              src={currentService?.image}
              alt={currentService?.title || "Imagem do serviço"}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default ServicosComponent;
