import { Col, Container, Row } from "react-bootstrap";
import photo from "../assets/images/fonbeat-afonso.jpeg";

const SobreComponent: React.FC = () => {
  return (
    <section className="section" id="sobre">
      <Container>
        <Row className="align-items-center">
          <Col lg={4}>
            <div className="mt-4">
              <img
                src={photo}
                alt="Afonso Inocente"
                style={{
                  width: "375px",
                  height: "515px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </div>
          </Col>
          <Col lg={7}>
            <div className="fonbeat-about-content mt-4 ps-5">
              <p className="text-muted f-14 text-uppercase letter-spacing_2">
                Um pouco sobre mim
              </p>
              <h3 className="fonbeat-about-title text-uppercase mt-3 letter-spacing_4">
                Afinal, quem é o <u>FONBEAT</u>?
              </h3>
              <div className="fonbeat-about-border mt-4"></div>
              <p className="text-muted line-height_1_8 mt-4 pt-2">
                Meu nome é <b>Afonso Inocente</b>, sou do interior de SP, uma
                cidade chamada Ibitinga, conhecida como a Capital Nacional do
                Bordado. Sou DJ desde 2020, porém, estou no ramo musical{" "}
                <b>desde 2011</b>. Já tive experiência com bandas, teatro,
                apresentações de eventos, já fui radialista, locutor, ou seja,
                tenho uma vasta experiência quando se trata de trabalhar com
                público, e isso me torna uma pessoa muito feliz, pois eu
                realmente <b>amo</b> este tipo de trabalho <br />É aquela famosa
                frase:{" "}
                <b>
                  "SE VOCÊ TRABALHAR COM O QUE VOCÊ AMA, VOCÊ NUNCA VAI
                  TRABALHAR"
                </b>
                , e posso dizer que na música essa frase se encaixa
                perfeitamente para mim! Pois o prazer, a alegria e o meu empenho
                em participar dos eventos que eu realizo, são, sem dúvidas, de
                outro mundo!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SobreComponent;
