import { Col, Container, Row } from "react-bootstrap";

const FooterComponent: React.FC = () => {
  return (
    <footer className="section agency-footer pb-5">
      <Container>
        {/* <Row>
                        <Col lg={3}>
                            <div>
                                <h6 className="footer-heading text-uppercase fw-bold f-13">Resources</h6>
                                <ul className="list-unstyled footer-link mt-3 mb-0">
                                    <li>
                                        <Link to="#">Monitoring Graden</Link>
                                    </li>
                                    <li>
                                        <Link to="#">video Tutorial</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Team &amp; Sevice</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Tulsy API</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div>
                                <h6 className="footer-heading text-uppercase fw-bold f-13">Help</h6>
                                <ul className="list-unstyled footer-link mt-3 mb-0">
                                    <li>
                                        <Link to="#">Sign Up </Link>
                                    </li>
                                    <li>
                                        <Link to="#">Login</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Terms of Services</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="fonbeat-instagram">
                                <h6 className="footer-heading text-uppercase fw-bold f-13">Instagram</h6>
                                <Carousel className="slide mt-4" indicators={false} controls={false}>
                                    <Carousel.Item className="carousel-item active">
                                        <img src={blog10} className="img-fluid" alt="..." />
                                    </Carousel.Item>
                                    <Carousel.Item className="carousel-item">
                                        <img src={blog12} className="img-fluid" alt="..." />
                                    </Carousel.Item>
                                    <Carousel.Item className="carousel-item">
                                        <img src={blog13} className="img-fluid" alt="..." />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </Col>

                        <Col lg={2}>
                            <div>
                                <h6 className="footer-heading text-uppercase fw-bold f-13">information</h6>
                                <ul className="list-unstyled footer-link mt-3 mb-0">
                                    <li>
                                        <Link to="#">Pages </Link>
                                    </li>
                                    <li>
                                        <Link to="#">Video Tutorial</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Term &amp; Service</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Tulsy API</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row> */}

        <Row className="mt-5">
          <Col lg={12}>
            <div className="p-4">
              <p className="copy-right text-center mb-0">
                ® FONBEAT - {new Date().getFullYear()}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;
