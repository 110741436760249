import "../assets/css/calendar.style.css"; // Importe o arquivo CSS
import React, { useState } from "react";
import { CalendarProps, CalendarEvent } from "../interfaces/calendar.interface";
import monthMap from "../utils/monthMap.util";

const CalendarComponent: React.FC<CalendarProps> = ({ calendar }) => {
  const obterMesAtual = () => {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    const dataAtual = new Date();
    return meses[dataAtual.getMonth()];
  };

  const [hoveredDate, setHoveredDate] = useState<number | null>(null);
  const [currentYear, setCurrentYear] = useState(2024);
  const [currentMonth, setCurrentMonth] = useState(obterMesAtual());

  const months = Object.keys(calendar[currentYear] || {});

  const handleNextMonth = () => {
    const currentIndex = months.indexOf(currentMonth);
    if (currentIndex < months.length - 1) {
      setCurrentMonth(months[currentIndex + 1]);
    } else {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(Object.keys(calendar[currentYear + 1])[0] || "Janeiro");
    }
  };

  const handlePrevMonth = () => {
    const currentIndex = months.indexOf(currentMonth);
    if (currentIndex > 0) {
      setCurrentMonth(months[currentIndex - 1]);
    } else {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(
        Object.keys(calendar[currentYear - 1]).slice(-1)[0] || "Dezembro"
      );
    }
  };

  const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const firstDayOfMonth = new Date(
    currentYear,
    monthMap[currentMonth],
    1
  ).getDay();
  const daysInMonth = new Date(
    currentYear,
    monthMap[currentMonth] + 1,
    0
  ).getDate();

  const handleMouseEnter = (day: number) => {
    setHoveredDate(day);
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
  };

  // Verifica a posição da célula no calendário para determinar a posição do tooltip
  const getTooltipClass = (index: number) => {
    const columnIndex = (firstDayOfMonth + index) % 7;
    return columnIndex >= 4 ? "event-tooltip-left" : "event-tooltip-right";
  };

  return (
    <div className="calendar-container" style={{ width: "800px" }}>
      <div className="calendar">
        <div className="calendar-header">
          <button className="calendar-button" onClick={handlePrevMonth}>
            &lt;
          </button>
          <h1 className="header-title">
            {currentMonth} {currentYear}
          </h1>
          <button className="calendar-button" onClick={handleNextMonth}>
            &gt;
          </button>
        </div>
        <div className="calendar-grid">
          {daysOfWeek.map((day) => (
            <div key={day} className="calendar-header-day">
              {day}
            </div>
          ))}
          {Array.from({ length: firstDayOfMonth }, (_, i) => (
            <div key={`empty-${i}`} style={{ width: "100%", height: "55px" }} />
          ))}
          {Array.from({ length: daysInMonth }, (_, i) => {
            const day = i + 1;
            const dayData = calendar[currentYear]?.[currentMonth]?.[day];
            const events = dayData?.events || [];
            const isPastDay =
              new Date(currentYear, monthMap[currentMonth], day) <
              new Date(new Date().setHours(0, 0, 0, 0));
            return (
              <div
                key={day}
                className={`calendar-day ${isPastDay ? "past-day" : ""} ${
                  events.length > 0 && !isPastDay ? "event-day" : ""
                }`}
                onMouseEnter={() => !isPastDay && handleMouseEnter(day)}
                onMouseLeave={() => !isPastDay && handleMouseLeave()}
              >
                {day}
                {!isPastDay && hoveredDate === day && events.length > 0 && (
                  <div
                    className={`event-tooltip event-tooltip-visible ${getTooltipClass(
                      i
                    )}`}
                  >
                    {events.map((event: CalendarEvent, index: number) => (
                      <div key={index} style={{ marginBottom: "0.5rem" }}>
                        <strong>{event.title}</strong>
                        <br />
                        <span>{event.description}</span>
                        <br />
                        <span>{event.time}</span>
                        {index < events.length - 1 && (
                          <hr style={{ margin: "0.5rem 0" }} />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
