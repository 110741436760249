import PricingData from "../interfaces/pricing.interface";

const pricingData: PricingData[] = [
  {
    title: "Básico",
    tag: "",
    price: "1.200,00",
    icon: "pe-7s-ribbon",
    active: "fonbeat-pricing-box",
    btnClass: "btn-dark-custom",
    iconStyle: {},
    features: [
      "DJ Open Format",
      "Som de Qualidade",
      "Iluminação Impecável",
      "Máquina de Fumaça",
    ],
    nonFeatures: [
      "Up Machine",
      "Papel Picado",
      "Gerb Indoor",
      "Bazuca CO2 de LED",
      "Painel de LED",
      "Pista de LED",
    ],
    link: "https://wa.me/5516996293155?text=Ol%C3%A1,%20eu%20gostaria%20de%20contratar%20os%20servi%C3%A7os%20do%20FONBEAT%20pro%20meu%20evento!%20Eu%20gostei%20do%20pacote%20*b%C3%A1sico*,%20que%20inclui%20os%20itens:%0A- DJ%20Open%20Format%0A- Som%20de%20qualidade%0A- Ilumina%C3%A7%C3%A3o%20impec%C3%A1vel%0A- M%C3%A1quina%20de%20Fuma%C3%A7a",
  },
  {
    title: "Intermediário",
    tag: "MAIS CONTRATADO",
    price: "1.950,00",
    icon: "pe-7s-star",
    active: "fonbeat-pricing-box-active",
    btnClass: "",
    iconStyle: { color: "#3CB371" },
    features: [
      "DJ Open Format",
      "Som de Qualidade",
      "Iluminação Impecável",
      "Máquina de Fumaça",
      "Up Machine",
      "Papel Picado",
      "Gerb Indoor",
      "Bazuca CO2 de LED",
    ],
    nonFeatures: ["Painel de LED", "Pista de LED"],
    link: "https://wa.me/5516996293155?text=Ol%C3%A1,%20eu%20gostaria%20de%20contratar%20os%20servi%C3%A7os%20do%20FONBEAT%20pro%20meu%20evento!%20Eu%20gostei%20do%20pacote%20*intermedi%C3%A1rio*,%20que%20inclui%20os%20itens:%0A- DJ%20Open%20Format%0A- Som%20de%20qualidade%0A- Ilumina%C3%A7%C3%A3o%20impec%C3%A1vel%0A- M%C3%A1quina%20de%20Fuma%C3%A7a%0A- Up%20Machine%0A- Papel%20Picado%0A- Gerb%20Indoor%0A- Bazuca%20CO2%20de%20LED",
  },
  {
    title: "Completo",
    tag: "",
    price: "4.500,00",
    icon: "pe-7s-ribbon",
    active: "fonbeat-pricing-box",
    btnClass: "btn-dark-custom",
    iconStyle: {},
    features: [
      "DJ Open Format",
      "Som de Qualidade",
      "Iluminação Impecável",
      "Máquina de Fumaça",
      "Up Machine",
      "Papel Picado",
      "Gerb Indoor",
      "Bazuca CO2 de LED",
      "Painel de LED",
      "Pista de LED",
    ],
    nonFeatures: [],
    link: "https://wa.me/5516996293155?text=Ol%C3%A1,%20eu%20gostaria%20de%20contratar%20os%20servi%C3%A7os%20do%20FONBEAT%20pro%20meu%20evento!%20Eu%20gostei%20do%20pacote%20*completo*,%20que%20inclui%20os%20itens:%0A- DJ%20Open%20Format%0A- Som%20de%20qualidade%0A- Ilumina%C3%A7%C3%A3o%20impec%C3%A1vel%0A- M%C3%A1quina%20de%20Fuma%C3%A7a%0A- Up%20Machine%0A- Papel%20Picado%0A- Gerb%20Indoor%0A- Bazuca%20CO2%20de%20LED%0A- Painel%20de%20LED%0A- Pista%20de%20LED",
  },
];

export default pricingData;
