import logo from "../assets/images/thumb-fundo-preto.webp";

export const videos = [
  {
    id: 1,
    title: "",
    thumbnail: logo,
    url: "https://www.youtube.com/embed/e7rCQm8fBSk?si=Mq2qFexuGHCKRlkO",
  },
  {
    id: 2,
    title: "",
    thumbnail: logo,
    url: "https://youtube.com/embed/-FRdGRsuZ-0",
  },
  {
    id: 3,
    title: "",
    thumbnail: logo,
    url: "https://youtube.com/embed/PWLNb3O5uPg",
  },
  {
    id: 4,
    title: "",
    thumbnail: logo,
    url: "https://youtube.com/embed/2oJJnRdXuqU",
  },
  {
    id: 5,
    title: "",
    thumbnail: logo,
    url: "https://youtube.com/embed/BJCDQBOxEZk",
  },
  {
    id: 6,
    title: "",
    thumbnail: logo,
    url: "https://youtube.com/embed/FmLK_gBIRFc",
  },
];
