import ServicesData from "../interfaces/service.interface";

import logo from "../assets/images/logo-fundo-preto.png";
import pistaDeLed from "../assets/images/pista-de-led-paris.webp";
import painelDeLed from "../assets/images/painel-de-led.png";
import efeitosDePalco from "../assets/images/efeitos-de-palco.png";
import gerb from "../assets/images/gerb-indoor.png";
import papelPicado from "../assets/images/papel-picado.png";
import bazucaCo2 from "../assets/images/bazuca-co2.png";

const servicesData: ServicesData[] = [
  {
    icon: "pe-7s-play",
    title: "DJ",
    descriptionAtModal: false,
    description:
      "DJ Open Format totalmente capacitado e com vasta experiência para realizar um verdadeiro show no seu evento.",
  },
  {
    icon: "pe-7s-music",
    title: "Som",
    descriptionAtModal: false,
    description:
      "Som de qualidade a gente gosta, né? E nada melhor do que ter a confiança de um som forte, com pressão e com a garantia de qualidade.",
  },
  {
    icon: "pe-7s-light",
    title: "Iluminação",
    descriptionAtModal: false,
    description:
      "Assim como a decoração, a iluminação faz um diferencial exuberante nos eventos. Uma boa iluminação muda tudo (e melhora as fotos).",
  },
  {
    icon: "pe-7s-star",
    title: "Pista de LED",
    descriptionAtModal: true,
    description:
      "Transforme o seu evento em uma verdadeira festa com uma pista de LED vibrante e animada. Deixe cada passo dançado brilhar e contagiar todos os convidados!",
    image: pistaDeLed,
  },
  {
    icon: "pe-7s-film",
    title: "Painel de LED",
    descriptionAtModal: true,
    description:
      "Para uma experiência visual inesquecível, conte com nossos painéis de LED de alta resolução. Exiba imagens, vídeos e conteúdos com qualidade impecável, criando uma atmosfera única.",
    image: painelDeLed,
  },
  {
    icon: "pe-7s-gleam",
    title: "Efeitos de Palco",
    descriptionAtModal: true,
    description:
      "Up machine, fogos indoor, papel picado, Bazuca CO2 de LED e muito mais! Surpreenda seus convidados com efeitos especiais de palco que deixam qualquer apresentação ainda mais emocionante.",
    image: efeitosDePalco,
  },
  {
    icon: "pe-7s-gleam",
    title: "Fogos Indoor",
    descriptionAtModal: false,
    description: "Entenda mais sobre este produto. Clique no icone (i) acima.",
    image: gerb,
  },
  {
    icon: "pe-7s-gleam",
    title: "Papel Picado",
    descriptionAtModal: false,
    description: "Entenda mais sobre este produto. Clique no icone (i) acima.",
    image: papelPicado,
  },
  {
    icon: "pe-7s-gleam",
    title: "Bazuca CO2 de LED",
    descriptionAtModal: false,
    description: "Entenda mais sobre este produto. Clique no icone (i) acima.",
    image: bazucaCo2,
  },
];

export default servicesData;
