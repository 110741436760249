import { Col, Container, Row } from "react-bootstrap";
import VideoModal from "./VideoModal.component";

const PortifolioComponent: React.FC = () => {
  return (
    <section className="section fonbeat-portifolio" id="portifolio">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                Portifólio
              </h2>
              <p className="heading-title-desc text-muted mt-4">
                Assista nossos videos e veja um pouco do nosso trabalho em:
                <br />
                formaturas, casamentos, aniversários e eventos onde estivemos
                presentes.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <VideoModal />
        </Row>
      </Container>
    </section>
  );
};

export default PortifolioComponent;
