import React from "react";
import BannerComponent from "../components/Banner.component";
import SobreComponent from "../components/Sobre.component";
import ServicosComponent from "../components/Servicos.component";
import PacotesComponent from "../components/Pacotes.component";
import AgendaComponent from "../components/Agenda.component";
import FooterComponent from "../components/Footer.component";
import ContactComponent from "../components/Contact.component";
import TimeComponent from "../components/Time.component";
import PortifolioComponent from "../components/Portifolio.component";

const Section = () => {
  return (
    <React.Fragment>
      {/* inicio */}
      <BannerComponent />
      <hr />

      {/* sobre */}
      <SobreComponent />
      <hr />

      {/* servicos */}
      <ServicosComponent />
      <hr />

      {/* pacotes */}
      <PacotesComponent />
      <hr />

      {/* agenda */}
      <AgendaComponent />
      <hr />

      {/* time */}
      <TimeComponent />
      <hr />

      {/* portifolio */}
      <PortifolioComponent />
      <hr />

      {/* Contact */}
      <ContactComponent />

      {/* Footer */}
      <FooterComponent />
    </React.Fragment>
  );
};

export default Section;
