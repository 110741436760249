import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactComponent: React.FC = () => {
  const [errorMsg, setErrorMsg] = useState("");

  const handleInputChange = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    // Access form field values
    const name = formData.get("name") as string;
    const comments = formData.get("comments") as string;

    const validationError = validateForm(name, comments);
    if (validationError) {
      setErrorMsg(validationError);
      return;
    }

    // Encode the message for URL
    const message = `Olá, meu nome é ${encodeURIComponent(
      name
    )}.%0A%0A${encodeURIComponent(comments)}`;
    const whatsappUrl = `https://wa.me/5516996293155?text=${message}`;

    // Redirect to the WhatsApp link
    window.open(whatsappUrl, "_blank");

    form.reset();
    setErrorMsg("");
  };

  const validateForm = (
    name: string | undefined,
    comments: string | undefined
  ) => {
    if (!name) {
      return "Por favor, informe o seu Nome.";
    }
    if (!comments) {
      return "Por favor, informe na mensagem como podemos te ajudar.";
    }
    return "";
  };

  return (
    <React.Fragment>
      <section className="section" id="contato">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="home-title text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  contato
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  Ficou com dúvidas ou quer realizar um orçamento personalizado?
                  <br />
                  Entre em contato conosco via Whatsapp e vamos conversar sobre
                  o seu evento.
                  <br />
                  Será um prazer atendê-lo! Estou te aguardando.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4">
            <Col lg={9}>
              <div className="fonbeat-contact-form mt-4">
                <h5 className="text-uppercase f-18 letter-spacing_2">
                  Digite sua mensagem
                </h5>
              </div>
              <div className="custom-form mt-4">
                <Form onSubmit={handleInputChange} name="myForm" id="myForm">
                  {errorMsg && (
                    <Alert variant="danger" className="error_message">
                      {errorMsg}
                    </Alert>
                  )}
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Seu Nome"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          name="comments"
                          id="comments"
                          as="textarea"
                          rows={5}
                          placeholder="Sua Mensagem"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="mt-2">
                        <Button
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-md btn-dark-custom"
                        >
                          Entrar em Contato
                        </Button>
                        <div id="simple-msg"></div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>

            <Col lg={3}>
              <div className="fonbeat-contact-info mt-3">
                <h5 className="text-uppercase f-18 letter-spacing_2">
                  whatsapp
                </h5>
                <div className="mt-4">
                  <Link
                    target="blank"
                    to="https://wa.me/5516996293155?text=Ol%C3%A1,%20eu%20gostaria%20de%20conhecer%20mais%20sobre%20o%20seu%20trabalho"
                    className="d-flex align-items-center"
                    style={{ textDecoration: "none" }}
                  >
                    <i
                      className="mdi mdi-whatsapp"
                      style={{
                        fontSize: "30px",
                        color: "#25d366",
                        marginRight: "10px",
                      }}
                    ></i>
                    <span className="fonbeat-whatsapp-font">
                      (16) 99629-3155
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactComponent;
