const calendar = {
  2024: {
    Outubro: {
      5: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
          {
            title: "Boteco do Tadeu",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      12: {
        events: [
          {
            title: "Evento Beneficente",
            description: "Itaju - SP",
            time: "",
          },
        ],
      },
      13: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      18: {
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      19: {
        events: [
          {
            title: "Casamento",
            description: "Ibitinga - SP",
            time: "",
          },
          {
            title: "Chama que ela vem",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      26: {
        events: [
          {
            title: "Playcommerce",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
    },

    Novembro: {
      1: {
        events: [
          {
            title: "Casamento",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      2: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      3: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      9: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Baile do Tadeu",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      16: {
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      23: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Casamento",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      24: {
        events: [
          {
            title: "Pagode do Presidente",
            description: "Tabatinga - SP",
            time: "",
          },
        ],
      },
      29: {
        events: [
          {
            title: "Colação de Grau",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      30: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
    },

    Dezembro: {
      4: {
        events: [
          {
            title: "Confraternização",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      6: {
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "21h - 03h",
          },
        ],
      },
      7: {
        events: [
          {
            title: "Aniversário",
            description: "Itápolis - SP",
            time: "20h - 03h",
          },
        ],
      },
      13: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "20h - 04h",
          },
        ],
      },
      14: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Aniversário",
            description: "Itápolis - SP",
            time: "20h - 03h",
          },
        ],
      },
      17: {
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "20h - 04h",
          },
        ],
      },
      21: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Confraternização",
            description: "Ibitinga - SP",
            time: "13h - 17h",
          },
          {
            title: "Formatura",
            description: "Itápolis - SP",
            time: "20h - 03h",
          },
          {
            title: "Festa",
            description: "Ibitinga - SP",
            time: "03:30h - 05h",
          },
        ],
      },
      22: {
        events: [
          {
            title: "Confraternização",
            description: "Ibitinga - SP",
            time: "19h - 00h",
          },
        ],
      },
      25: {
        events: [
          {
            title: "Sunrise de Natal",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      28: {
        events: [
          {
            title: "Aniversário",
            description: "Itápolis - SP",
            time: "21h - 02h",
          },
        ],
      },
      31: {
        canGetAnotherEvent: true,
        events: [
          {
            title: "Réveillon do Dom",
            description: "Ibitinga - SP",
            time: "04h - 08h",
          },
        ],
      },
    },
  },

  2025: {
    Janeiro: {
      4: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "19h - 01h",
          },
        ],
      },
      11: {
        events: [
          {
            title: "Formatura",
            description: "Ibitinga - SP",
            time: "21h - 03h",
          },
        ],
      },
      18: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
      25: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "20h - 03h",
          },
        ],
      },
    },

    Fevereiro: {
      8: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "20h - 04h",
          },
        ],
      },
      22: {
        events: [
          {
            title: "Jantar",
            description: "Ibitinga - SP",
            time: "21h - 03h",
          },
        ],
      },
    },

    Março: {
      8: {
        events: [
          {
            title: "Casamento",
            description: "Ibitinga - SP",
            time: "18:30h - 02h",
          },
        ],
      },
    },

    Abril: {},

    Maio: {
      3: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "",
          },
        ],
      },
    },

    Junho: {},

    Julho: {},

    Agosto: {},

    Setembro: {
      20: {
        events: [
          {
            title: "Casamento",
            description: "Araraquara - SP",
            time: "",
          },
        ],
      },
      27: {
        events: [
          {
            title: "Aniversário",
            description: "Ibitinga - SP",
            time: "20h - 01h",
          },
        ],
      },
    },

    Outubro: {},

    Novembro: {},

    Dezembro: {},
  },

  2026: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2027: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2028: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2029: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2030: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2031: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2032: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2033: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2034: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },

  2035: {
    Janeiro: {},
    Fevereiro: {},
    Março: {},
    Abril: {},
    Maio: {},
    Junho: {},
    Julho: {},
    Agosto: {},
    Setembro: {},
    Outubro: {},
    Novembro: {},
    Dezembro: {},
  },
};

export default calendar;
