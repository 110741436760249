import { Col, Container, Row } from "react-bootstrap";
import CalendarComponent from "./Calendar.component";
import calendar from "../consts/calendar.const";

const AgendaComponent: React.FC = () => {
  return (
    <section className="section fonbeat-agenda" id="agenda">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                agenda de Eventos
              </h2>
              <p className="heading-title-desc text-muted mt-4">
                Quer saber se a data do seu evento está disponível?
                <br />
                Confira agora mesmo os nossos eventos agendados para os próximos
                meses.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 pt-3">
          <CalendarComponent calendar={calendar} />
        </Row>
      </Container>
    </section>
  );
};

export default AgendaComponent;
