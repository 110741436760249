import React from "react";

const LinksPage: React.FC = () => {
  const links = [
    { title: "Site", icon: "mdi mdi-earth", url: "https://fonbeat.com.br/" },
    {
      title: "Whatsapp",
      icon: "mdi mdi-whatsapp",
      url: "https://api.whatsapp.com/message/R47PERY7VNTDC1",
    },
    {
      title: "Instagram",
      icon: "mdi mdi-instagram",
      url: "https://instagram.com/FONBEAT",
    },
    {
      title: "Youtube",
      icon: "mdi mdi-youtube",
      url: "https://youtube.com/@FONBEAT",
    },
    // {
    //   title: "Portfólio",
    //   icon: "mdi mdi-briefcase",
    //   url: "https://fonbeat.com.br/#portifolio",
    // },
  ];

  const styles = {
    container: {
      backgroundColor: "#000",
      color: "#fff",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center",
      padding: "20px",
    },
    profileSection: {
      textAlign: "center" as const,
    },
    profileImage: {
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      border: "3px solid #fff",
      overflow: "hidden",
      transform: "scale(2)",
      transition: "transform 0.3s ease-in-out",
      objectFit: "cover" as "cover",
      objectPosition: "38%",
      margin: "50px 0",
    },
    profileLogo: {
      width: "300px",
      marginTop: "29px",
    },
    profileDescription: {
      fontSize: "1rem",
      color: "#d4d4d4",
    },
    linksSection: {
      width: "100%",
      maxWidth: "400px",
    },
    linkButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#111",
      color: "#fff",
      textDecoration: "none",
      padding: "15px",
      borderRadius: "10px",
      marginBottom: "15px",
      fontSize: "1.2rem",
      fontWeight: "bold",
      transition: "all 0.3s",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
    },
    linkButtonHover: {
      backgroundColor: "#fff",
      color: "#000",
    },
    linkButtonIcon: {
      marginRight: "10px",
      fontSize: "1.5rem",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.profileSection}>
        <img
          style={styles.profileImage}
          src={require("../assets/images/background/background-banner.jpeg")}
          alt="Afonso Inocente"
        />
        <p>
          <img
            src={require("../assets/images/fonbeat-logo.png")}
            alt="Logo FONBEAT"
            style={styles.profileLogo}
          />
        </p>
        <p style={styles.profileDescription}>DJ Open Format | Produtor</p>
      </div>
      <div style={styles.linksSection}>
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.linkButton}
            onMouseOver={(e) =>
              Object.assign(e.currentTarget.style, styles.linkButtonHover)
            }
            onMouseOut={(e) =>
              Object.assign(e.currentTarget.style, {
                backgroundColor: "#111",
                color: "#fff",
              })
            }
          >
            <i className={link.icon} style={styles.linkButtonIcon}></i>
            {link.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LinksPage;
