import Typewriter from "typewriter-effect";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const BannerComponent: React.FC = () => {
  return (
    <section className="section fonbeat-inicio" id="inicio">
      {/* <video autoPlay muted loop className="background-video">
        <source
          src="http://casa-porto.s3.amazonaws.com/afonso/video3.webm"
          type="video/webm"
        />
      </video> */}
      <div className="bg-overlay"></div>
      <div className="bg-overlay" style={{ opacity: "0.5" }}></div>
      <div className="home-center">
        <div className="home-desc-center">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center text-white">
                  <p className="fonbeat-inicio-subtitle text-uppercase letter-spacing_2">
                    Olá, eu sou o{" "}
                    <b>
                      <u style={{ fontSize: "18px" }}>FONBEAT</u>
                    </b>
                    !
                  </p>
                  <h4 className="intro mb-25 mt-25 ">
                    <span className="typing2">Eu sou o DJ certo&nbsp;</span>
                  </h4>
                  <br />
                  <h4 className="intro mb-25 mt-25">
                    <span className="typing2">
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: true,
                          delay: 22,
                          deleteSpeed: 16,
                          strings: [
                            "",
                            " pra sua festa!",
                            " pro seu casamento!",
                            " pro seu aniversário!",
                            " pra sua confraternização!",
                            " pra sua formatura!",
                            " pro seu jantar!",
                            " pra sua festa de natal!",
                            " pro seu happy hour!",
                            " pro seu réveillon!",
                          ],
                        }}
                      />
                    </span>
                  </h4>
                  <h4 className="fonbeat-text-uppercase mt-4">
                    <span className="element" data-elements=""></span>
                  </h4>
                  <p className="fonbeat-inicio-desc f-15 mt-4 mx-auto line-height_1_8">
                    Sou DJ Open Format, consigo abrangir todas as vertentes
                    musicais, e dessa forma, montar um <b>show perfeito</b> para
                    o seu evento!
                  </p>
                  <div className="mt-4">
                    <ul className="list-inline fonbeat-inicio-social mb-0">
                      <li className="list-inline-item">
                        <Link
                          target="blank"
                          to="https://www.instagram.com/fonbeat/"
                          className="rounded-circle"
                        >
                          <i
                            className="mdi mdi-instagram"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          target="blank"
                          to="https://wa.me/5516996293155?text=Ol%C3%A1,%20eu%20gostaria%20de%20conhecer%20mais%20sobre%20o%20seu%20trabalho"
                          className="rounded-circle"
                        >
                          <i
                            className="mdi mdi-whatsapp"
                            style={{ fontSize: "30px", marginLeft: "20px" }}
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default BannerComponent;
